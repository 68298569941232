import Logo from './images/johs.png';
export let SERVER_URL = "https://www.johs.org.uk/";
export const APP_NAME="Journal of Healthcare Simulation";
export const LOGO=Logo;
export const FbLoginID=5542443729116713;
export const AppleClientId="co.novareader.researchpad";
export const GmailClientId="458582913994-ufc1of4nlhc3vmc9h89rdhupeon86bss.apps.googleusercontent.com"
export const OrcidClientId="https://orcid.org/oauth/authorize?client_id=APP-NE6GOPUF09930F9B&response_type=code&scope=/authenticate&redirect_uri=https://www.johs.org.uk/login"
export const URL="https://www.johs.org.uk/";
export const AppVersion="6";
export const RedirectUri="https://www.johs.org.uk/api/applelogin"
export const Cover="https://www.johs.org.uk/unsecured/";