import Logo from './images/ips.png';
export const SERVER_URL = "https://demo.novareader.co/";
export const APP_NAME="Nova Reader";
export const LOGO=Logo;
export const FbLoginID=365059365513232;
export const AppleClientId="co.novareader.researchpad";
export const GmailClientId="458582913994-ufc1of4nlhc3vmc9h89rdhupeon86bss.apps.googleusercontent.com"
export const OrcidClientId="https://orcid.org/oauth/authorize?client_id=APP-NE6GOPUF09930F9B&response_type=code&scope=/authenticate&redirect_uri=https://demo.novareader.co/login"
export const URL="https://demo.novareader.co";
export const AppVersion="6";
export const RedirectUri="https://demo.novareader.co/api/applelogin"
export const Cover="https://demo.novareader.co/unsecured/";