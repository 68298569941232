import React, { useState, useEffect } from "react";
import { Modal} from 'antd';
//import GoogleLogin from 'react-google-login';
import { GoogleOAuthProvider, GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import NormalLoginForm from './NormalLoginForm';
import NormalSiginForm from './NormalSiginForm';
import InstLogin from './instLoginForm';
import { AppleLogin } from 'react-sign-in-apple';
import { useHistory } from 'react-router-dom';
import orcid from '../../asset/images/orcid.png';
import { ArrowLeftOutlined, LoadingOutlined,BankOutlined } from '@ant-design/icons';
import NormalForgotPasswordForm from './NormalForgotPasswordForm';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { resendMail, googlesignin, fblogin, logo, fbloginid, url, orcidlogin, applelogin, appleclientid, gmailclientid,orcidclientid ,redirectUri} from '../../api/api';
import { FacebookLoginButton, GoogleLoginButton, AppleLoginButton, createButton, createSvgIcon } from "react-social-login-buttons";
import './styles.css';

const Login = (props) => {
  const history = useHistory();
  const [signupForm, setsignupForm] = useState(false);
  const [loginForm, setloginForm] = useState(true);
  const [forgotForm, setforgotForm] = useState(false);
  const [isgmaillogin, setisgmaillogin] = useState(false);
  const [instForm, setinstForm] = useState(false);
  const [isfblogin, setisfblogin] = useState(false);
  const [isorcid, setisorcid] = useState(false)
  const [isapplelogin, setisapplelogin] = useState(false);

  const config = {
    text: "Log in with Orcid",
    icon: createSvgIcon(orcid),
    iconFormat: name => `fa fa-${name}`,
    style: { background: "#87F575" },
    activeStyle: { background: "#87F575" }
  };
  const ORCIDLoginButton = createButton(config);

  useEffect(() => {
    const query = new URLSearchParams(props.location.search);
    const token = query.get('code')
    const appletoken = query.get('token')
    if (token) {
      setisorcid(true)
      getOrcidtoken(token)
    }else if(appletoken){
      setisapplelogin(true)
      getappletoken(appletoken)

    }
     else {
      const lpp_userCredentials = JSON.parse(localStorage.getItem('lpp_userCredentials'));
      if (lpp_userCredentials != null) {
        if (lpp_userCredentials.emailVerified == 1) {
          history.replace("/")
        }
      }
    }

  }, [])

  const signUp = () => {
    setsignupForm(true);
    setloginForm(false);
    setforgotForm(false)
    setinstForm(false)

  }
  const backtoLogin = () => {
    setsignupForm(false);
    setloginForm(true);
    setforgotForm(false)
    setinstForm(false)

  }
  const forgotPassword = () => {
    setsignupForm(false);
    setloginForm(false);
    setforgotForm(true)
    setinstForm(false)

  }

  const instLogin = () => {
    setsignupForm(false);
    setloginForm(false);
    setforgotForm(false)
    setinstForm(true)

  }



  const getOrcidtoken = async (code) => {
    var formdata = new FormData();
    formdata.append("token", code)
    formdata.append("redirect_uri", url+"login")
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };
    let data = await fetch(orcidlogin, requestOptions)
    let result = await data.json()
    const loginRes = await checkLogin(result);
    if (loginRes == true) {
      history.replace("/")
    } else {
      setisorcid(false)
      window.location.href = url+"login";
    }
}
const getappletoken = async (code) => {
  var formdata = new FormData();
  formdata.append("token", code)
  formdata.append("redirect_uri", redirectUri)
  var requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow'
  };
  let data = await fetch(applelogin, requestOptions)
  let result = await data.json()
  const loginRes = await checkLogin(result);
  if (loginRes == true) {
    history.replace("/")
  } else {
    setisapplelogin(false)
    window.location.href = url+"login";
  }
}

  const responseGoogle = async (response) => {
    console.log("responseGOogle print",response)
    if (response != undefined && response.error == undefined) {
      setisgmaillogin(true)
      var formdata = new FormData();
      console.log("access_token1",response.credential, typeof response.credential)
      formdata.append("token", response.credential)
    //https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?gsiwebsdk=3&client_id=343724709682-7k9o0e0dhrv2ee0koa28gcumplv6a5ud.apps.googleusercontent.com&scope=openid%20profile%20email&redirect_uri=storagerelay%3A%2F%2Fhttps%2Fmobile.vijaynicole.co.in%3Fid%3Dauth976&prompt=select_account&response_type=token&include_granted_scopes=true&enable_granular_consent=true&service=lso&o2v=2&ddm=0&flowName=GeneralOAuthFlow
    https://accounts.google.com/signin/oauth/consent?authuser=0&part=AJi8hAOn6opM_-MEMHuRN63OUwjjddOSaZEhFe3vk-nG_samekfOW3F621Hib14mV0ivXeW54R8TTkU3e8r9jsEeEh9L1DbfTUdMwYvZxJOSOiY5nkBs5hIoPmenGpRtI0hr6ojksEESFx52HcdQ4S1l3fXI-kN4KKfoLVDDe8OjaYpjb-4qzoTZwhghEuQBwQmfOyPQNrDkEXa9ZdEEJqSBjffl26n5LNPw2dZkSxfQOhwS1pulm0T8i5dkH312f9vgAQTORn5gtkW7iIIpR4GdWkJM-xuupq4-GkIO8nZNVZ5Dh6WnoqmkFbcNDuwBcoo5zQhlDKR2mpUFVqRW9RZ5niF-TUvF8xCQC6Cgnb_sYXDsoY8PmjZNkxFP1oYmwVDwykQ8F9Cn_mzUABGRYSrNnxJnqTQw1scF8yOmUjn-PdeUGs56cgTgMBcVhINjfjJD5WtwNfNSkPK-83DKwYgtYFF9dvtvKJ01Ek4myiijCiF4MsZxMuY&flowName=GeneralOAuthFlow&as=S419795414%3A1721192490047282&client_id=343724709682-7k9o0e0dhrv2ee0koa28gcumplv6a5ud.apps.googleusercontent.com&pli=1&rapt=AEjHL4NQ-GVHZdImv6SP6IBUvkHXPJ9YHvyfMtm7Gd9HbzovDqWFzDI_NQXWBoocXjEzcPdRfwcU2ESJfC3cotCym2TIDDvyRw#
      var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
      };
      console.log("formdata",requestOptions);
      let data = await fetch(googlesignin, requestOptions)
      let result = await data.json()
      console.log("google login results",result)
      const loginRes = await checkLogin(result);
      setisgmaillogin(false)
      if (loginRes == true) {
        history.replace("/")
      }


    }
  }
  const login = useGoogleLogin({
    onSuccess: tokenResponse => console.log(tokenResponse),
  });
  const responseApple = async (response) => {
    setisapplelogin(true)
    var formdata = new FormData();
    formdata.append("token", response.accessToken)
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };
    let data = await fetch(applelogin, requestOptions)
    let result = await data.json()
    const loginRes = await checkLogin(result);
    if (loginRes == true) {
      history.replace("/")
    } else {
      setisapplelogin(false)

    }
  }
  const responseFacebook = async (response) => {
    console.log("fbres", response)
    if (!isorcid) {
      if (response.status != "unknown") {
        setisfblogin(true)
        var formdata = new FormData();
        formdata.append("token", response.accessToken)
        var requestOptions = {
          method: 'POST',
          body: formdata,
          redirect: 'follow'
        };
        let data = await fetch(fblogin, requestOptions)
        let result = await data.json()
        const loginRes = await checkLogin(result);
        if (loginRes == true) {
          history.replace("/")
        }
      }
    }
  }

  const orcidOpen = () => {
     window.location.href = orcidclientid;
  }

  const checkLogin = async (result) => {
    if (result.cred === 0) {
      Modal.error({
        title: 'Login failed',
        content: 'Wrong password. Try again or click Forgot password to reset it.',
      });
      return false;
    }
    else if (result.cred === 2) {
      Modal.warning({
        title: 'Invalid Email-ID',
        content: 'Your email address is not registered. Please register with us.',
      });
      return false;
    }
    else if (result.cred === 3) {
      Modal.warning({
        title: 'Login Failed',
        content: 'Incorrect Email or Wrong password. Try again or click Forgot password to reset it.',
      });
      return false;

    }
    else if (result.cred === 1) {
      let user_details = {
        apikey: result.apikey,
        usermail: result.email,
        email: result.username,
        emailVerified: result.emailVerified || result.emailverified,
        displayName: result.displayName,
        fuzzyId:result.fuzzyId,
        _id: result._id,
        guser: 0
      };
      if (user_details['emailVerified'] === 0 || user_details['emailVerified'] === undefined) {
        let homePage = await getEmailVerification(user_details.email);
        console.log("finalresponse", homePage)
        if (homePage.status === 202) {
          {
            Modal.info({
              title: 'Verification',
              content: 'Thank you for registering to the Nova Reader Mobile application. The link has been sent to your registered email id to verify your email account. Please follow the email to complete verification process before downloading the eBooks.',
            });
          }
        }
      }
      else {

        localStorage.setItem('lpp_userCredentials', JSON.stringify(user_details));
        return true;
      }
    }
    else {
      Modal.warning({
        title: 'Login Failed',
        content: 'Oops error, Please try again',
      });
      return false;

    };
  }
  const getEmailVerification = async (usermail) => {
    var formdata = new FormData();
    formdata.append("email", usermail);

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    let data = await fetch(resendMail, requestOptions);
    let result = await data.json();
    return result
  }
  
  const isFacebookApp = () => {
    const ua = navigator.userAgent || navigator.vendor || window.opera;
    return (
      ua.indexOf('FBAN') > -1 ||
      ua.indexOf('FBAV') > -1 ||
      ua.indexOf('Instagram') > -1
    );
  };
  return (
    <div className="login_continer" >
      <div className="loginPage">
        <div className="logImg">
          <img style={{ width: '140px', marginTop: '20px' }} src={logo} alt="" />
        </div>
        <div></div>
        {loginForm ? <div>
          <div className="loginHead">Login</div>
          {/* <div className="inst" onClick={instLogin}><BankOutlined style={{marginRight:'10px' }}/>Sign in through your Institution</div> */}

          <NormalLoginForm gologin={() => backtoLogin()} />
          <div className="login_fooder">
            <div className="login-foot">Don't has an account? <span onClick={signUp}>SignUp</span></div>
            <div className="forget-text" onClick={forgotPassword}>Forgot Password?</div>
          </div>
        </div> : ""}
        {signupForm ? <div>
          <div onClick={backtoLogin} className="loginHead"><ArrowLeftOutlined style={{ marginRight: '10px' }} />Create Account</div>
          <NormalSiginForm gologin={() => backtoLogin()} />
        </div> : ""}
        {forgotForm ? <div>
          <div onClick={backtoLogin} className="loginHead"><ArrowLeftOutlined style={{ marginRight: '10px' }} />Forgot Password</div>
          <NormalForgotPasswordForm />
        </div> : ""}
        {instForm ? <div>
          <InstLogin />
          <div className="login_fooder">
            <div className="login-foot" style={{textAlign:'center'}}>Can't find your institution?</div>
            <div className="forget-text" style={{textAlign:'center'}} onClick={backtoLogin}>Sign via username and password</div>
          </div>
        </div> : ""}
        <div style={{ width: '100%', marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#D7D4D3' }}>Or Login With</div>
        <div className="loginButton" style={{alignItems: 'center', justifyContent: 'center'}}>
        <GoogleOAuthProvider clientId={gmailclientid}>
        
        <GoogleLogin shape={"circle"}
  onSuccess={responseGoogle}
  width={470}
  onError={() => {
    console.log('Login Failed');
  }}
/>      
        </GoogleOAuthProvider>
    
        
          {/* <GoogleLogin
            clientId={gmailclientid}
            render={renderProps => (
              <GoogleLoginButton size={"40px"} style={{ marginTop: '15px', fontSize: "15px" }} onClick={renderProps.onClick} >
                {isgmaillogin ? <span>Loading... <LoadingOutlined style={{ marginLeft: '10px' }} /></span> : <span>Log in with Gmail</span>}
              </GoogleLoginButton>)}
            buttonText="Login"
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            cookiePolicy={'single_host_origin'}
          /> */}
          <FacebookLogin
            autoLoad={false}
            appId={fbloginid}
            fields="name,email"
            isMobile={false}
            redirectUri={url}
            disableMobileRedirect={true}
            callback={responseFacebook}
            render={renderProps => (
              <FacebookLoginButton size={"40px"} style={{ marginTop: '15px', fontSize: "15px" }} onClick={renderProps.onClick} >
                {isfblogin ? <span>Loading... <LoadingOutlined style={{ marginLeft: '10px' }} /></span> : <span>Log in with Facebook</span>}
              </FacebookLoginButton>)} />
          <AppleLogin
            clientId={appleclientid}
            redirectURI={redirectUri}
            onSuccess={responseApple}
            onFailure={console.error}
            render={renderProps => (
              <AppleLoginButton size={"40px"} style={{ marginTop: '15px', fontSize: "12px",borderRadius:'22px', fontWeight:200, textAlign:"center" }} align="center" onClick={renderProps.onClick} >
                {isapplelogin ? <span>Loading... <LoadingOutlined style={{ marginLeft: '10px',  }} /></span> : <span>Sign in with Apple</span>}
              </AppleLoginButton>)} />
          <div onClick={orcidOpen} style={{ height: '40px', backgroundColor: '#fff',display:'flex', marginTop: '15px', borderRadius: '22px', boxShadow: "1px 1px 3px 0.5px #9E9E9E", alignItems: 'center', marginLeft: '5px', marginRight: '5px', textAlign:'center' }}>
            <img style={{ width: '30px'}} src={orcid} alt="" />
            {isorcid ? <span> Loading... <LoadingOutlined style={{ marginLeft: '5px' }} /></span> : <span style={{ marginLeft: '25%' }}> Sign in with Orcid</span>}
          </div>
        </div>
      </div></div>);

}

export default Login;